/**
 * Finder toggle accordion
 */
const findToggle = () => {
  const rows = document.querySelectorAll('.js-faq-accordion');

  if (rows) {
    [...rows].forEach(row => {
      row.addEventListener('click', () => {
        row.classList.toggle('active');
      });
    });
  }
};

export default findToggle;