/**
 * Slider popular
 */
declare var Splide: any;

const splidePopular = () => {
  const popularSlider = document.querySelector('.js-slider-popular');
  if (popularSlider) {
    //@ts-ignore
    var events = new Splide( popularSlider, {
      type: 'loop',
      autoplay: true,
      interval: 2000,
      perPage: 3,
      perMove: 1,
      pagination: true,
      focus: 'center',
      gap: '16px',
      fixedWidth: '305px',
      fixedHeight: '165px',
      breakpoints: {
        767: {
          perPage: 3,
          fixedWidth: '121px',
          fixedHeight: '65px',
          gap: '6px',
        },
        // 1200: {
        //   perPage: 3,
        //   fixedWidth: '25%'
        // }
      },
    });
    
    events.mount();
  }
};

export default splidePopular;