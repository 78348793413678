import test from './components/test';
import splideImage from './components/splideImage';
import splidePopular from './components/splidePopular';
import findToggle from './components/findToggle';

document.addEventListener(
  'DOMContentLoaded',
  () => {
    test();
    splideImage();
    splidePopular();
    findToggle();
  },
  false
);
