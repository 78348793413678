/**
 * Slider Image
 */
declare var Splide: any;

const splideImage = () => {
  const imageSlider = document.querySelector('.js-slider');
  if (imageSlider) {
    //@ts-ignore
    var events = new Splide( imageSlider, {
      type: 'loop',
      autoplay: true,
      interval: 2000,
      perPage: 3,
      perMove: 1,
      pagination: true,
      focus: 'center',
      gap: '16px',
      fixedWidth: '311px',
      fixedHeight: '175px',
      breakpoints: {
        767: {
          perPage: 3,
          fixedWidth: '244px',
          fixedHeight: '137px',
        },
        // 1200: {
        //   perPage: 3,
        //   fixedWidth: '25%'
        // }
      },
    });
    
    events.mount();
  }
};

export default splideImage;